@import "~@/styles/variables/variables.scss";









































































































































.liquid-fill-chart {
	width: 70px;
	height: 70px;
}
