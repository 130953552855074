@import "~@/styles/variables/variables.scss";





























































































































































































































































.ring-chart {
	margin-top: 20px;
	width: 100%;
	height: 230px;
}
