@import "~@/styles/variables/variables.scss";






































































































































.device-status {
	position: absolute;
	top: 165px;
	bottom: 280px;
	left: 10px;
	width: 22%;
	max-width: 430px;
	background: $bs-bg;
	.top-modal {
		display: flex;
		align-items: center;
		margin-top: 20px;
		& > img {
			width: 46px;
		}
		.label {
			margin: 12px;
		}
		.item-right {
			margin-left: 20px;
			.number {
				font-size: 28px;
			}
			em {
				font-size: 12px;
			}
		}
	}
	.items {
		display: flex;
		flex-wrap: wrap;
		.item {
			margin-top: 32px;
			width: 50%;
			display: flex;
			align-items: center;
			.icon-status {
				width: 46px;
			}
			.item-right {
				margin-left: 10px;
				flex: 1;
				padding-right: 6px;
				.number {
					display: inline-block;
					margin-top: 10px;
					margin-right: 8px;
					font-size: 20px;
					font-family: DIN Alternate;
					font-weight: bold;
				}
			}
		}
	}
}
