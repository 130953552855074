@import "~@/styles/variables/variables.scss";

















































































































































































































































































































































.map-container {
	width: 100%;
	height: 100%;
	position: relative;
	#container {
		width: 100%;
		height: 100%;
		position: absolute;
		background: $bs-bg-header;
	}
}
::v-deep .amap-info-contentContainer {
	.content {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 479px;
		height: 287px;
		background: url('~@/assets/imgs/screen/bg-detail.png') no-repeat;
		background-size: cover;
		background-position: center;
		padding: 50px 35px 30px;
		.main {
			flex: 1;
			display: flex;
			.icon {
				width: 76px;
				height: 62px;
			}
			.info {
				flex: 1;
				margin-left: 20px;
				font-family: Lantinghei SC;
				font-weight: 600;
				position: relative;
				.alarm {
					position: absolute;
					top: 0;
					right: 0;
					color: #d81e06;
					display: flex;
					flex-direction: column;
					align-items: center;
					.iconfont {
						font-size: 20px;
					}
					.count {
						line-height: 18px;
						font-size: 12px;
					}
				}
				.title {
					font-size: 22px;
					width: 260px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.row {
					margin-top: 12px;
					opacity: 0.6;
					display: flex;
					line-height: 20px;
					&:first-of-type {
						margin-top: 17px;
					}
					.label {
						width: 70px;
						+ span {
							flex: 1;
						}
					}
					.address {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
				}
			}
		}
		.btn {
			@include flex-center;
			height: 34px;
			background: rgba(121, 209, 255, 0.16);
			border: 1px solid #79d1ff;
			border-radius: 3px;
			cursor: pointer;
		}
	}
}
::v-deep {
	.el-dialog {
		height: calc(100vh - 120px);
		overflow: hidden;
		border-radius: 12px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		.el-dialog__header {
			display: none;
		}
		.el-dialog__body {
			padding: 0;
			flex: 1;
		}
		.el-dialog__footer {
			@include flex-center;
			button {
				width: 190px;
				height: 48px;
				border-radius: 24px;
				+ button {
					margin-left: 16px;
				}
			}
		}
	}
}
