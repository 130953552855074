@import "~@/styles/variables/variables.scss";





























































































.gc-blank-page.empty {
	margin-top: 10px;
}
::v-deep .icon-caution {
	margin-left: 6px;
	font-size: 12px;
	color: #ec6b60;
}
.alarm-list {
	position: absolute;
	bottom: 10px;
	left: 10px;
	width: 76%;
	height: 258px;
	background: $bs-bg;
	.box-table {
		margin-top: 10px;
		font-size: 12px;
		.table-content {
			height: 140px;
			overflow: auto;
			.tr {
				background: rgba(124, 157, 223, 0.1);
				cursor: pointer;
			}
		}
		.tr {
			margin-bottom: 8px;
			height: 40px;
			display: flex;
			align-items: center;
			padding-left: 20px;
			.th {
				color: #79d1ff;
			}
			.th,
			.td {
				flex: 1;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				&:nth-of-type(1) {
					max-width: 80px;
				}
				&:nth-of-type(4) {
					min-width: 140px;
					padding-right: 20px;
				}
				&:nth-of-type(8) {
					min-width: 180px;
				}
			}
		}
	}
}
