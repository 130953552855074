@import "~@/styles/variables/variables.scss";












































































.current-alarm {
	position: absolute;
	top: 97px;
	bottom: 10px;
	right: 10px;
	width: 22%;
	max-width: 430px;
	background: $bs-bg;
	.items {
		display: flex;
		flex-wrap: wrap;
		.item {
			margin-top: 36px;
			width: 100px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-right: calc((100% - 402px) / 3);
			&:nth-child(4n) {
				margin-right: 0;
			}
			.box-ball {
				text-align: center;
				@include flex-center;
				.icon-ball {
					height: 70px;
				}
			}
			.ball-label {
				margin-top: 20px;
				font-size: 12px;
				text-align: center;
			}
		}
	}
}
