@import "~@/styles/variables/variables.scss";






















































































.search {
	position: absolute;
	top: 97px;
	left: 27%;
	right: 27%;
	width: 46%;
	height: 50px;
	background: $bs-bg;
	border: 1px solid $bs-border-color;
	display: flex;
	align-items: center;
	::v-deep {
		.el-autocomplete {
			.el-input__inner {
				padding-left: 150px;
				padding-right: 50px;
			}
			.el-input__prefix .el-input__inner {
				padding-left: 15px;
				padding-right: 0;
			}
			.el-input__suffix {
				@include flex-center;
			}
		}
		.el-select {
			width: 130px;
			position: relative;
			&::after {
				content: '';
				height: 19px;
				border-right: 1px solid #fff;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		.el-input__inner {
			background: none;
			border: none;
			color: #fff;
		}
	}

	.icon-search {
		cursor: pointer;
		font-size: 18px;
		padding-right: 15px;
	}
}
.drop-list {
	position: fixed;
	top: 155px;
	left: 27%;
	right: 27%;
	width: 46%;
	max-height: 300px;
	overflow: auto;
	background: $bs-bg;
	border: 1px solid $bs-border-color;
	padding: 8px 20px;
	.item {
		margin-top: 8px;
		height: 40px;
		background: rgba(124, 189, 255, 0.2);
		display: flex;
		align-items: center;
		font-size: 12px;
		padding: 0 20px;
		cursor: pointer;
		&:first-of-type {
			margin-top: 0;
		}
		span {
			&:nth-of-type(1) {
				flex: 1;
			}
			&:nth-of-type(2) {
				flex: 2;
				padding-left: 20px;
			}
			&:nth-of-type(3) {
				flex: 3;
				padding-left: 20px;
			}
		}
	}
}
