@import "~@/styles/variables/variables.scss";







































































































































.bar-chart {
	margin-top: 20px;
	width: 100%;
	height: 200px;
}
