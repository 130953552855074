@import "~@/styles/variables/variables.scss";


















































































.header {
	position: absolute;
	top: 0;
	left: 10px;
	right: 10px;
	height: 74px;
	background: url('~@/assets/imgs/screen/bg-header.png') no-repeat;
	background-size: cover;
	background-position: center;
	font-family: PangMenZhengDao-3;
	font-weight: 600;
	color: #80a4ed;
	.box-time {
		position: absolute;
		left: 20px;
		top: 34px;
		display: flex;
		align-items: center;
		.time {
			font-size: 28px;
			width: 150px;
		}
		.date {
			font-size: 16px;
			.day {
				margin-top: 4px;
				font-size: 12px;
			}
		}
	}
	.img-title {
		width: 316px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.entry {
		cursor: pointer;
		position: absolute;
		right: 5%;
		top: 35px;
		font-size: 16px;
		border-bottom: 1px solid #80a4ed;
		padding-bottom: 8px;
	}
}
