@import "~@/styles/variables/variables.scss";





























































.big-screen-wrapper {
	width: 100%;
	height: 100vh;
	position: relative;
	background: #000;
	color: #fff;
	overflow: hidden;
	.main-wrapper {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.layer-wrapper {
		position: absolute;
		left: 50%;
		top: 50%;
		overflow: hidden;

		pointer-events: none; // 图层事件穿透

		* {
			// 其它事件恢复
			pointer-events: auto;
		}
	}
}
