@import "~@/styles/variables/variables.scss";































































































































::v-deep {
	.gc-blank-page {
		margin-top: 50px;
		.no-data-img {
			width: initial;
			height: 100px;
		}
	}
}
.screen-container {
	width: 100vw;
	min-width: 1200px;
	height: 100vh;
	background: linear-gradient(180deg, rgba(124, 189, 255, 0.12) 0%, rgba(124, 189, 255, 0.12) 100%);
	position: relative;
	color: #fff;
}
.box-select {
	position: absolute;
	top: 97px;
	left: 10px;
	width: 22%;
	max-width: 430px;
	height: 50px;
	background: $bs-bg;
	border: 1px solid #7c9ddf;
	display: flex;
	align-items: center;
	.label {
		width: 100px;
		text-align: center;
		line-height: 19px;
		border-right: 1px solid #fff;
	}
	::v-deep .el-select {
		height: 100%;
		& > .el-input {
			height: 100%;
			.el-input__inner {
				height: 100%;
				background: none;
				border: none;
				color: #fff;
			}
		}
	}
}
.z-top {
	z-index: 9;
}
.mask {
	z-index: 8;
	&.top {
		position: fixed;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	&.bottom {
		position: fixed;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	&.left {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
	}
	&.right {
		position: fixed;
		bottom: 0;
		right: 0;
		height: 100%;
	}
}
