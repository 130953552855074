@import "~@/styles/variables/variables.scss";




























































































































































































































































.pie-chart {
	margin-top: 20px;
	width: 100%;
	height: 200px;
}
